<template>
    <div>
        <GmapMap
                :center="center"
                :zoom="mapZoom"
                map-type-id="terrain"
                style="width: 100%; height: 400px"
                :options="mapOptions"
        >
            <GmapMarker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    :clickable="true"
                    :draggable="false"
                    @click="toggleInfoWindow(m, index)"
            />
            <GmapInfoWindow
                    v-if="infoWinOpen"
                    :options="infoOptions"
                    :position="infoWindowPos"
            >
            </GmapInfoWindow>
        </GmapMap>
        <section class="section bg-gray-alt py-4">
            <b-container>
                <b-row>
                    <b-col lg="7">
                        <h1>{{ $t('message.contact.title_header') }}</h1>
                        <p class="lead mb-0">
                            {{ $t('message.contact.subtitle') }}
                        </p>
                    </b-col>
                    <b-col md="5">
                        <div class="header-breadcrumb">
                            <router-link class="link-alt" :to="{ name: $i18n.locale + '_home' }">{{ $t('message.home.title') }}</router-link>
                            • <span class="text-muted">{{ $t('message.contact.title') }}</span>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </section>
        <section class="section">
            <b-container>
                <b-row>
                    <b-col lg="8">
                        <div v-if="emailError" class="email-error d-flex justify-content-center align-items-center">
                            <div class="text-center">
                                <div class="li-caution text-danger fa-5x mb-2"></div>
                                <p>{{ $t('message.contact.error') }}</p>
                                <b-btn variant="primary" @click="emailError = false">{{ $t('message.contact.try_again') }}</b-btn>
                            </div>
                        </div>
                        <div v-else-if="emailSuccess" class="email-success d-flex justify-content-center align-items-center">
                            <div class="text-center">
                                <div class="li-envelope text-success fa-5x mb-2"></div>
                                <p>{{ $t('message.contact.success') }}</p>
                                <b-btn variant="primary" @click="emailSuccess = false">{{ $t('message.contact.send_another_request') }}</b-btn>
                            </div>
                        </div>

                        <b-form v-else @submit="onSubmit">
                            <b-row>
                                <b-col md="6">
                                    <b-form-group
                                            :label="$t('message.contact.your_name')"
                                            label-for="name"
                                    >
                                        <b-form-input
                                                id="name"
                                                v-model="form.name"
                                                type="text"
                                        ></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group
                                            :label="$t('message.contact.your_company')"
                                            label-for="company"
                                    >
                                        <b-form-input
                                                id="company"
                                                v-model="form.company"
                                                type="text"
                                        ></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col md="6">
                                    <b-form-group
                                            :label="$t('message.contact.your_email')"
                                            label-for="email"
                                    >
                                        <b-form-input
                                                :state="false === emailBlured ? null : emailValid"
                                                @blur="emailBlured = true"
                                                id="email"
                                                v-model="form.email"
                                                type="email"
                                                required
                                        ></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6">
                                    <b-form-group
                                            :label="$t('message.contact.your_phone')"
                                            label-for="phone"
                                    >
                                        <b-form-input
                                                id="phone"
                                                v-model="form.phone"
                                                type="text"
                                        ></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-form-group
                                    :label="$t('message.contact.subject.title')"
                                    label-for="subject">
                                <b-form-select
                                        v-model="form.subject"
                                        id="subject"
                                        name="subject"
                                        :options="subjectOptions">
                                </b-form-select>
                            </b-form-group>

                            <b-form-group
                                    :label="$t('message.contact.your_message')"
                                    label-for="message"
                            >
                                <b-form-textarea
                                        :state="false === messageBlured ? null : messageValid"
                                        @blur="messageBlured = true"
                                        id="message"
                                        v-model="form.message"
                                        rows="14"
                                        required
                                ></b-form-textarea>
                            </b-form-group>

                            <button class="btn btn-outline-primary mb-3" type="submit" :disabled="isLoading || !emailValid|| !messageValid" @click="submitClick">
                                <span class="li-envelope"></span>
                                {{ $t('message.contact.send_enquiry') }}
                            </button>
                        </b-form>
                    </b-col>
                    <b-col lg="4">
                        <div class="heading-title heading-border-bottom heading-margin-top"><h4>{{ $t('message.contact.headquarters_emea.title') }}</h4></div>
                        <address>
                            <ul class="fa-ul">
                                <li>
                                    <span class="fa-li li-map-pin"></span>
                                    <span v-html="$t('message.contact.headquarters_emea.address')"></span>
                                </li>
                                <li><span class="fa-li li-phone"></span>{{ $t('message.contact.headquarters_emea.phone') }}</li>
                                <li><span class="fa-li li-envelope"></span><a :href="'mailto: ' + $t('message.contact.headquarters_emea.email')">{{ $t('message.contact.headquarters_emea.email') }}</a></li>
                            </ul>
                        </address>
                        <div class="heading-title heading-border-bottom heading-margin-top"><h4>{{ $t('message.contact.headquarters_americas.title') }}</h4></div>
                        <address>
                            <ul class="fa-ul">
                                <li>
                                    <span class="fa-li li-map-pin"></span>
                                    <span v-html="$t('message.contact.headquarters_americas.address')"></span>
                                </li>
                                <li><span class="fa-li li-phone"></span>{{ $t('message.contact.headquarters_americas.phone') }}</li>
                                <li><span class="fa-li li-envelope"></span><a :href="'mailto: ' + $t('message.contact.headquarters_americas.email')">{{ $t('message.contact.headquarters_americas.email') }}</a></li>
                            </ul>
                        </address>
                        <div class="heading-title heading-border-bottom heading-margin-top"><h4>{{ $t('message.contact.social') }}</h4></div>
                        <a class="link-alt" href="https://twitter.com/syntelix" target="_blank" rel="noopener" aria-label="Syntelix Twitter">
                            <span class="li-twitter fa-2x mr-2"></span>
                        </a>
                        <a class="link-alt" href="https://www.linkedin.com/company/syntelix" target="_blank" rel="noopener" aria-label="Syntelix LinkedIn">
                            <span class="li-linkedin fa-2x"></span>
                        </a>
                    </b-col>
                </b-row>
                <hr>
                <p><small class="text-muted">{{ $t('message.contact.footer') }}</small></p></b-container>
        </section>
    </div>
</template>

<script>
    import Email from '@/assets/js/smtp'
    export default {
        name: 'Contact',
        metaInfo () {
            return  {
                htmlAttrs: {
                    lang: this.$i18n.locale
                },
                title: this.$t('meta.contact.title'),
                link: [
                    { rel: 'canonical', href: 'https://www.syntelix.net' + this.$route.path.replace(/\/$/, "") }
                ],
                meta: [
                    {
                        vmid: 'description',
                        name: 'description',
                        content: this.$t('meta.contact.description')
                    },
                    {
                        vmid: 'og:title',
                        property: 'og:title',
                        content: this.$t('meta.contact.title')
                    },
                    {
                        vmid: 'og:description',
                        property: 'og:description',
                        content: this.$t('meta.contact.description')
                    },
                    {
                        vmid: 'og:type',
                        property: 'og:type',
                        content: 'website'
                    },
                    {
                        vmid: 'og:image',
                        property: 'og:image',
                        content: 'https://syntelix-website.s3-eu-west-1.amazonaws.com/og.jpg'
                    },
                    {
                        vmid: 'og:url',
                        property: 'og:url',
                        content: 'https://www.syntelix.net' + this.$route.path
                    }
                ],
                script: [{
                    type: 'application/ld+json',
                    json: {
                        "@context": "http://schema.org",
                        "@type": "Corporation",
                        "legalName": "Syntelix Avances Tecnológicos S.L.",
                        "brand": "Syntelix",
                        "name": "Syntelix",
                        "description": this.$t('meta.home.description'),
                        "url": "https://www.syntelix.net",
                        "email": "info@syntelix.net",
                        "faxNumber": "(+34) 986 064 336",
                        "logo": 'https://www.syntelix.net' + require('@/assets/images/logo.png'),
                        "telephone": "(+34) 911 938 062",
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "PTL Valladares Calle C, Nave C4",
                            "addressLocality": "Vigo",
                            "addressCountry": "España",
                            "postalCode": "36314"
                        }
                    }
                }]
            }
        },
        data() {
            return {
                isLoading: false,
                email: Email,
                emailError: false,
                emailSuccess: false,
                form: {
                    name: '',
                    company: '',
                    email: '',
                    phone: '',
                    subject: 'General inquiry',
                    message: ''
                },
                emailBlured: false,
                messageBlured: false,
                center: {
                    lat: 36.486969,
                    lng: -43.453926
                },
                mapZoom: 3,
                mapOptions: {
                    disableDefaultUI: true
                },
                infoWindowPos: null,
                infoWinOpen: false,
                currentMidx: null,

                infoOptions: {
                    content: '',
                    //optional: offset infowindow so it visually sits nicely on top of our marker
                    pixelOffset: {
                        width: 0,
                        height: -35
                    }
                },
                markers: [
                    {
                        position: {
                            lat: 42.176557,
                            lng: -8.708789
                        },
                        infoText: '<strong>Syntelix Avances Tecnológicos S.L.</strong><br/>' +
                            'PTL Valladares<br/>' +
                            'Calle C, Nave C4<br/>' +
                            '36315 Vigo, SPAIN<br/>' +
                            'info@syntelix.net<br/>' +
                            'T. (+34) 986 064 300'
                    }, {
                        position: {
                            lat: 9.012044,
                            lng: -79.533910
                        },
                        infoText: '<strong>Syntelix Panama Corp.</strong><br/>' +
                            'Office #240, 2º Floor<br/>' +
                            'Ave. Ricardo J Alfaro, Plz. Aventura Business Center.<br/>' +
                            'Panama City, PANAMA<br/>' +
                            'americas@syntelix.net  <br/>' +
                            'T. (+507) 388 3473'
                    }
                ]
            }
        },
        computed: {
            subjectOptions() {
                return [
                    { value: 'General inquiry', text: this.$t('message.contact.subject.general_inquiry') },
                    { value: 'Service in a specific country', text: this.$t('message.contact.subject.service_in_a_specific_country') },
                    { value: 'Jobs', text: this.$t('message.contact.subject.jobs') },
                    { value: 'Products', text: this.$t('message.contact.subject.products') },
                    { value: 'Others', text: this.$t('message.contact.subject.others') },
                ]
            },

            emailValid() {
                return this.form.email && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email)
            },
            messageValid() {
                return this.form.message.length > 0;
            }
        },

        mounted() {
            // eslint-disable-next-line no-console
            if (window.innerWidth > 540) {
                this.mapZoom = 3
            } else {
                this.mapZoom = 2
            }
        },

        methods: {
            toggleInfoWindow: function(marker, idx) {
                this.infoWindowPos = marker.position;
                this.infoOptions.content = marker.infoText;

                //check if its the same marker that was selected if yes toggle
                if (this.currentMidx == idx) {
                    this.infoWinOpen = !this.infoWinOpen;
                }
                //if different marker set infowindow to open and reset current marker index
                else {
                    this.infoWinOpen = true;
                    this.currentMidx = idx;
                }
                this.center = {
                    lat: marker.position.lat + 10,
                    lng: marker.position.lng
                }
            },

            submitClick() {
                this.emailBlured = true
                this.messageBlured = true
            },
            onSubmit(evt) {
                this.isLoading = true
                evt.preventDefault()
                this.email.send({
                    SecureToken : "a9aaf56a-ffca-4441-9969-efc8cda61f2b",
                    To : process.env.VUE_APP_CONTACT_EMAILS,
                    From : "relay@syntelix.net",
                    Subject : "[syntelix.net] New contact request",
                    Body : `Name: ${this.form.name}<br/>Company: ${this.form.company}<br/>Email: ${this.form.email}<br/>Phone: ${this.form.phone}<br/>Subject: ${this.form.subject}<br/>Message: ${this.form.message}<br/>URL: ${window.location}`
                }).then(
                    message => {
                        this.isLoading = false
                        if (message === 'OK') {
                            this.emailSuccess = true
                        } else {
                            this.emailError = true
                        }
                    }
                );
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../scss/bootstrap_variables_custom";

    @include media-breakpoint-up(md) {
        .email-error,
        .email-success {
            min-height: 740px;
        }
    }
</style>

